// hamburger
$(document).ready(function () {
    $('.hamburger-wrap').click(function () {
        $('.header__btn').addClass('header__btn--active');
        $('.header__logo').addClass('header__logo--notActive');
        $('nav').addClass('nav--active');
        $('header').addClass('header--active');
        $('body').addClass('active');
    });

    // close mobile menu
    $('.cross').click(function () {
        $('.header__btn').removeClass('header__btn--active');
        $('.header__logo').removeClass('header__logo--notActive');
        $('nav').removeClass('nav--active');
        $('header').removeClass('header--active');
        $('body').removeClass('active');
        $('.nav__heading--menu').removeClass('nav__heading--menu--active');
        $('.nav__heading--main').removeClass('nav__heading--main--notActive');
        $('.nav__heading--sub').text('Pages');
        $('.nav__submenu').removeClass('nav__submenu--active');
    });

    // mobile menu
    $('.nav__link--main').on('click', function (e) {
        e.preventDefault();
        var link = $(this).text();
        var value = $(this).attr('data-value');;
        $('.nav__heading--sub').text(link);
        $('.nav__heading--main').addClass('nav__heading--main--notActive');
        $('.nav__heading--menu').addClass('nav__heading--menu--active');
        $("#menu" + value).toggleClass('nav__submenu--active');
    });
    $('.nav__heading--menu').on('click', function () {
        $(this).removeClass('nav__heading--menu--active');
        $('.nav__heading--main').removeClass('nav__heading--main--notActive');
        $('.nav__heading--sub').text('Pages');
        $('.nav__submenu').removeClass('nav__submenu--active');
    });

    // testimonial slider
    $('#testimonial-slider__wrap').slick({
        speed: 2000,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,
        slidesToShow: 1,
        dots: false,
        useTransform: true,
        pauseOnHover: true,
        lazyLoad: 'progressive',
        cssEase: 'ease-in-out',
        prevArrow: $(".testimonial-slider").find(".testimonial-slider__arrow--left"),
        nextArrow: $(".testimonial-slider").find(".testimonial-slider__arrow--right"),
    });

    // image slider
    $('.image-slider__wrap').slick({
        speed: 2000,
        autoplay: false,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        slidesToShow: 1,
        dots: false,
        useTransform: true,
        pauseOnHover: false,
        lazyLoad: 'progressive',
        cssEase: 'ease-in-out',
        prevArrow: $(".image-slider").find(".image-slider__arrow--left"),
        nextArrow: $(".image-slider").find(".image-slider__arrow--right"),
    });

    // image slider
    $('.logo-list__wrap--slider').slick({
        speed: 1800,
        autoplay: true,
        autoplaySpeed: 1500,
        slidesToScroll: 1,
        dots: false,
        useTransform: true,
        pauseOnHover: false,
        centerMode:true,
        lazyLoad: 'progressive',
        cssEase: 'ease-in-out',
        arrows: false,
        infinite:true,
        responsive: [
            {
                breakpoint: 10000,
                settings: {
                  slidesToShow: 9,
                }
            },
            {
                breakpoint: 1500,
                settings: {
                  slidesToShow: 8,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                  slidesToShow: 7,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 6,
                }
            },
            {
                breakpoint: 992,
                settings: {
                  slidesToShow: 5,
                }
            },
            {
                breakpoint: 768,
                settings: {
                  slidesToShow: 4,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    });


    if (window.matchMedia('(min-width: 992px)').matches) {
        $('.icon-block--effect--3').hover(
            function() {
                $(this).prev().css( "transform", "none" );
                $(this).prev().children('.btn').css("opacity", "0");
            }, function() {
                $(this).prev().css( "transform", "scale(1.15)" );
                $(this).prev().children('.btn').css("opacity", "1");
            }
        );
        $('.nav__listItem--sub').hover(
            function() {
                $(this).find('.nav__submenu').addClass('active');
            }
        );
        $('.nav__footer').hover(
            function() {
                $(this).prevAll('.nav__listItem--sub').children('.nav__submenu.active').css( "opacity", "1" );
            }, function() {
                $(this).prevAll('.nav__listItem--sub').children('.nav__submenu.active').css( "opacity", "0" );
            }
        );
    }

    // Animation fade in
    window.setTimeout(function(){
        $('.fade-in:in-viewport').addClass('animate');
    }, 800)
    $(window).scroll(function() {
        $('.fade-in:in-viewport').addClass('animate');
    });

    window.setTimeout(function(){
        $('.fade-in-image:in-viewport').addClass('animate');
    }, 4000)
    $(window).scroll(function() {
        $('.fade-in-image:in-viewport').addClass('animate');
    });

    window.setTimeout(function(){
        $('.fade-in-center:in-viewport').addClass('animate');
    }, 5000)
    $(window).scroll(function() {
        $('.fade-in-center:in-viewport').addClass('animate');
    });

    window.setTimeout(function(){
        $('.fade-in-alt:in-viewport').addClass('animate');
    }, 5000)
    $(window).scroll(function() {
        $('.fade-in-alt:in-viewport').addClass('animate');
    });
})


// function getViewportOffset($e) {
//     var $window = $(window),
//       scrollLeft = $window.scrollLeft(),
//       scrollTop = $window.scrollTop(),
//       offset = $e.offset(),
//       rect1 = { x1: scrollLeft, y1: scrollTop, x2: scrollLeft + $window.width(), y2: scrollTop + $window.height() },
//       rect2 = { x1: offset.left, y1: offset.top, x2: offset.left + $e.width(), y2: offset.top + $e.height() };
//     return {
//       left: offset.left - scrollLeft,
//       top: offset.top - scrollTop,
//       insideViewport: rect1.x1 < rect2.x2 && rect1.x2 > rect2.x1 && rect1.y1 < rect2.y2 && rect1.y2 > rect2.y1
//     };
//   }


// New code
/* Steps slider on mobile */
function slickify(){
    const sliderContainer = $(".list-block__slider");
    if(sliderContainer){
        $(".list-block__slider").slick({
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      arrows:false,
                      centerMode:true,
                      infinite:false
                    }
                },
                {
                    breakpoint:10000,
                    settings: "unslick"
                }
            ]
        });
    }
}
slickify();
$(window).resize(function(){
    slickify();
    const winWidth = $(window).width();
    if(winWidth > 768){
        $(".team__slider-arrows-item--left, .team__slider-arrows-item--right")
        .removeClass("team__slider-arrows-item--disabled");
    }
    else{
        $(".team__slider-arrows-item--left, .team__slider-arrows-item--right")
        .addClass("team__slider-arrows-item--disabled"); 
    }
});
/* Team members slider */
if($(window).width() <= 768){
    $(".team__slider-arrows-item--left").addClass("team__slider-arrows-item--disabled");
}
$(".team__slider").slick({
    prevArrow:$(".team__slider-arrows-item--left"),
    nextArrow:$(".team__slider-arrows-item--right"),
    infinite:false,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
                vertical: true,
            }
        },
        {
            breakpoint: 10000,
            settings: {
              slidesToShow: 3,
              rows: 2,
              vertical:false
            }
        }
    ]
});
$(".team__slider").on("afterChange", function(event,slick, currentSlide, nextSlide){
    const winWidth = $(window).width();
    if(winWidth <= 768){
        const childrenCount = $(this).find(".block-image").length;
        currentSlide == 0 ?
            $(".team__slider-arrows-item--left").addClass("team__slider-arrows-item--disabled"):
            $(".team__slider-arrows-item--left").removeClass("team__slider-arrows-item--disabled");
        currentSlide == childrenCount - 3 ?
        $(".team__slider-arrows-item--right").addClass("team__slider-arrows-item--disabled"):
        $(".team__slider-arrows-item--right").removeClass("team__slider-arrows-item--disabled");
    }
});
//  Apprentice upload file functionnality
$("#apprentice-file").on("change", function(e){
    const filename = $(this)[0].files.length ? $(this)[0].files[0].name : "";
    if(filename.length){
        $(".apprentice-form__form-input-text").text(filename);
    }
});
$(".services__slider").slick({
    slidesToShow:4,
    variableWidth:true,
    prevArrow:$(".services__slider-arrow--left"),
    nextArrow:$(".services__slider-arrow--right"),
});
// Go to specific service page when selectbox is changed 
$(".filter-block__select--services").on("change", function(e){
    window.location.href = $(this).val();
});
$(".logo-list__wrap--md-slider").slick({
    responsive:[
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1.5,
                arrows:false,
                centerMode:true,
                infinite:true,
                speed: 2000,
                autoplay: true,
                autoplaySpeed: 3000,
                cssEase: 'ease-in-out',
            }
        },
        {
            breakpoint: 10000,
            settings: "unslick"
        } 
    ]
});
$(window).resize( () => {
    if($(window).width() <= 768){
        $(".logo-list__wrap--md-slider").slick('refresh');
    }
});
// Toggle paragraphs when read more is clicked
$(".hero__more").on("click", function(e){
    $(".hero__subheading:not(:first)").toggleClass("hero__subheading--hidden");    
    $(".hero__more").toggleClass("hero__more--open");    
});
// Toggle FAQ when question is clicked
$(".faqs__question").on("click", function(e){
    $(this).next().toggleClass("faqs__answer--open");
});
$(".faqs__item").each(function(ind, elm) {
    $(elm).css('z-index', ind * 2);
});

// Initialize and add the map
function initMap() {
    let container = document.getElementById("address-map");
    if(container){
        const lat = Number($("#address-map").data("lat"));
        const lng = Number($("#address-map").data("lng"));
        const location = { lat, lng };
        const map = new google.maps.Map(document.getElementById("address-map"), {
          zoom: 15,
          center: location,
          disableDefaultUI: true,
          draggable:false
        });
        // The marker, positioned at Uluru
        const marker = new google.maps.Marker({
          position: location,
          map: map,
        });
        if($(window).width() >= 992){
            map.panBy(-140, 10);
        }
    }
  }
  
  $(document).ready(function() {
    $('form').on('submit', function(e) {
        if (grecaptcha.getResponse() === '') {
            e.preventDefault();
            alert('Please verify the reCAPTCHA before submitting the form.');
        }
    });
});

window.initMap = initMap;
window.addEventListener('load', function(){
    const mapElement = document.querySelector('#map-container');
    if(mapElement){
        const googleMapsApiKey = document.querySelector('span[data-api-key]').dataset.apiKey;
        const observer = new IntersectionObserver(function(entries) {
            if(entries[0].isIntersecting === true) {
                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&callback=initMap&v=weekly`;
                document.body.appendChild(script);
                // remove span that holds data-api-key
                document.querySelector('span[data-api-key]').remove();
            }
            }, { threshold: [0] 
        });
        observer.observe(mapElement);
    }
});