var lastScrollTop = 0;
if(window.scrollY > 1714){
    $(".hero__explore").addClass("hero__explore--absolute");
}
else{
    $(".hero__explore").removeClass("hero__explore--absolute");
}
$(window).scroll(function () {
    if(window.innerWidth > 768){
        var st = window.pageYOffset || document.documentElement.scrollTop;
        $(".hero--main .hero__content-layer--1").css("top", Math.max(0 + 0.3*window.scrollY, 0) + "px");
        $(".hero--main .hero__content-layer--1").css("opacity", Math.max(1 - 0.002*window.scrollY, 0));
        if (st > lastScrollTop){
            $(".hero--main .hero__content-layer--2").css("bottom", Math.max(0 + 0.3*(window.scrollY - 200), 0) + "px");
            $(".hero--main .hero__content-layer--2").css("opacity", 1 - Math.max(1 - 0.002*(window.scrollY - 200), 0));
            if(window.scrollY > 800){
                $(".hero--main .hero__content-layer--2").css("opacity", Math.max(1 - 0.002*(window.scrollY - 800), 0));
            }
            if(window.scrollY > 1200){
                $(".hero--main .hero__content-layer--3").css("opacity", 1 - Math.max(1 - 0.002*(window.scrollY - 1200), 0));
                $(".hero--main .hero__content-layer--3").css("bottom", Math.max(0 + 0.3*(window.scrollY - 1200), 0) + "px");
            }
            if(window.scrollY > 1714){
                $(".hero__explore").addClass("hero__explore--absolute");
                $(".hero--main .hero__content-layer--3").css({
                    "opacity":1 - Math.max(0.003*(window.scrollY - 1714), 0)
                });
            }
            else{
                $(".hero__explore").removeClass("hero__explore--absolute");
            }
         }
        else {
            $(".hero--main .hero__content-layer--2").css("bottom", Math.max(0 + 0.3*(window.scrollY - 200), 0) + "px");
            $(".hero--main .hero__content-layer--2").css("opacity", 1 - Math.max(1 - 0.002*(window.scrollY - 200), 0));
            $(".hero--main .hero__content-layer--3").css("opacity", 1 - Math.max(1 - 0.002*(window.scrollY - 1200), 0));
            $(".hero--main .hero__content-layer--3").css("bottom", Math.max(0 + 0.3*(window.scrollY - 1200), 0) + "px");
            if(window.scrollY > 800){
                $(".hero--main .hero__content-layer--2").css("opacity", Math.max(1 - 0.002*(window.scrollY - 800), 0));
            }
            if(window.scrollY > 1200){
                $(".hero--main .hero__content-layer--3").css("opacity", 1 - Math.max(1 - 0.002*(window.scrollY - 1200), 0));
                $(".hero--main .hero__content-layer--3").css("bottom", Math.max(0 + 0.3*(window.scrollY - 1200), 0) + "px");
            }
            if(window.scrollY > 1714){
                $(".hero__explore").addClass("hero__explore--absolute");
                $(".hero--main .hero__content-layer--3").css({
                    "opacity":1 - Math.max(0.003*(window.scrollY - 1714), 0)
                });
            }
            else{
                $(".hero__explore").removeClass("hero__explore--absolute");
            }
        }
        lastScrollTop = st <= 0 ? 0 : st;
        $('.hero--main .hero__layer--bg').css({
            'transform': 'scale(' + (1.05 - $(window).scrollTop() / 60000) + ')'
        });
        $('.hero--main .hero__layer--1').css({
            'transform': 'scale(' + (1 + $(window).scrollTop() / 5000) + ')'
        });
        $('.hero--main .hero__layer--2').css({
            'transform': 'translate3d(10px, 0, 0) scale(' + (1 + $(window).scrollTop() / 5000) + ')'
        });
        $('.hero--main .hero__layer--3').css({
            'transform': 'translate3d(-10px, 0, 0) scale(' + (1 + $(window).scrollTop() / 5000) + ')'
        });
    }
});